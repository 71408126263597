import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box, Collapse, Typography, useTheme } from "@mui/material";
import TaskViewQuotesListItemActions from "./TaskViewQuotesListItemActions";

interface TaskViewQuotesListItemProps {
  quotes: Array<CoreState.Quote.Quote>;
  open: boolean;
  onViewClick(quote: CoreState.Quote.Quote): void;
}
const TaskViewQuotesListItem = (props: TaskViewQuotesListItemProps) => {
  const { open, quotes, onViewClick } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: -3,
        px: 3,
        mb: {
          xs: 1,
          md: 3
        },
        background: theme.palette.grey[50]
      }}
    >
      <Collapse in={open}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
            textTransform: "uppercase",
            pt: 1.75,
            mb: {
              xs: 0.75,
              md: 0
            },
            ml: {
              xs: "26px",
              md: 0
            }
          }}
        >
          Quote History
        </Typography>
        {quotes.map((quote, idx) => (
          <Box
            key={idx}
            sx={{
              mx: -3,
              px: 3,
              display: "grid",
              columnGap: 1,
              gridTemplateAreas: {
                xs: `
                'company actions'
              `,
                md: `'. quote actions'`
              },
              gridTemplateColumns: {
                md: "auto 170px 270px",
              },
              pb: 1.75,
              mb: 1.75,
              borderBottom: `dotted 2px ${theme.palette.grey[200]}`,
              "&:last-child": {
                mb: 0,
                border: "none"
              }
            }}
          >
            <CoreComponents.QuoteListCost
              cost={quote.cost}
              sx={{
                gridArea: {
                  xs: "company",
                  md: "quote"
                },
                ml: {
                  xs: "26px",
                  md: 0
                },
                position: "relative",
              }}
            >
              <Box sx={{ p: 2 }}>
                <CoreModules.Task.TaskView.TaskViewTimelinePaymentSchedule
                  paymentSchedule={quote.paymentSchedule}
                />
              </Box>
            </CoreComponents.QuoteListCost>
            <TaskViewQuotesListItemActions
              status={quote.status}
              onAcceptClick={() => { }}
              onDeclineClick={() => { }}
              onViewClick={() => onViewClick(quote)}
            />
          </Box>
        ))}
      </Collapse>
    </Box>
  )
}
export default TaskViewQuotesListItem;