import { ChatRouteDefinitions, CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { selectProjectTask } from "modules/project/_selectors";
import { useEffect, useState } from "react";
import TaskViewRouteDefinitions from "../../TaskViewRouteDefinitions";
import { useTaskViewParams } from "../../_hooks";
import TaskViewQuotesRouteDefinitions from "../TaskViewQuotesRouteDefinitions";
import TaskViewQuoteListItemHistory from "./TaskViewQuoteListItemHistory";
import TaskViewQuotesListItemActions from "./TaskViewQuotesListItemActions";
import TaskViewQuotesListItemRating from "./TaskViewQuotesListItemRating";
import TaskViewQuotesListItemRoutes from "./TaskViewQuotesListItemRoutes";
import TaskViewQuotesListTemplate from "./TaskViewQuotesListTemplate";

interface TaskViewQuotesListItemProps {
  quote: CoreState.Quote.Quote;
  history: Array<CoreState.Quote.Quote>;
}
const TaskViewQuotesListItem = (props: TaskViewQuotesListItemProps) => {
  const { quote, history } = props;
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId } = useTaskViewParams()
  const taskParams = { projectId, stageId, taskId };
  const quoteRequestListParams = { projectId, stageId, taskId };
  const noteThreadParams = { projectId, stageId, taskId };

  // selectors
  const latestQuoteRequest = useSelector((s) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(s, quoteRequestListParams));
  const projectTask = useSelector((s) => selectProjectTask(s, taskParams))
  const messages = useSelector((s) => CoreState.NoteThread.selectNoteThreadListByLatestQuoteRequest(s, noteThreadParams));

  // queries
  const noteThreadListQuery = CoreState.NoteThread.getNoteThreadListByLatestQuoteRequest.useQuery(noteThreadParams);

  // mutations
  const [rejectQuoteAction, rejectQuoteQuery] = CoreState.Quote.rejectQuote.useMutation();
  const [addQuoteNoteThreadAction, addQuoteNoteThreadQuery] = CoreState.NoteThread.addQuoteNoteThread.useMutation();

  // locals
  const [open, setOpen] = useState(false);
  const [showQuoteHistory, setShowQuoteHistory] = useState(false);
  const showQuoteHistoryButton = Boolean(history.length);
  const isSaving = Boolean(rejectQuoteQuery.isLoading);
  const rating = quote.overallCompanyRating || 0;

  // event handlers
  const handleViewCompany = () => {
    if (!quote.company?.id) return;
    const params = { qid: quote.id, cid: quote.company.id };
    routing.go(TaskViewQuotesRouteDefinitions.TASK_VIEW_QUOTES_COMPANY, { params })
  };

  const handleViewClick = (quote: CoreState.Quote.Quote) => {
    const params = { qid: quote.id }
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUOTES_VIEW, { params });
  }

  const handleViewMessage = () => {
    const found = messages?.find(m =>
      m.members?.includes(quote.company?.id as string)
    );

    if (found) {
      const params = { id: found.id };
      routing.go(ChatRouteDefinitions.VIEW_CHAT, { params })
      return;
    }

    setOpen(true)
  }

  const handleShowQuoteHistory = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowQuoteHistory(!showQuoteHistory);
  }

  const handleAddMessage = (values: any) => {
    if (!quote.company?.id || !latestQuoteRequest) return;
    addQuoteNoteThreadAction({
      projectId,
      stageId,
      taskId,
      quoteRequestId: latestQuoteRequest.id,
      toUserId: quote.company.id,
      content: values.message,
      title: values.topic,
    })
  }

  const handleAccept = () => {
    if (!quote.id) return;
    const params = { qid: quote.id };
    routing.go(TaskViewRouteDefinitions.TASK_VIEW_QUOTES_ACCEPT, { params })
  }

  const handleDecline = () => {
    rejectQuoteAction({ projectId, quoteId: quote.id })
  }

  useEffect(() => {
    if (!addQuoteNoteThreadQuery.isSuccess) return;
    setOpen(false);
  }, [addQuoteNoteThreadQuery.isSuccess])


  if (noteThreadListQuery.isLoading) return null;

  return (
    <>
      <Box
        sx={{
          position: "relative",
          borderBottom: showQuoteHistory ? "none" : `dotted 2px ${theme.palette.grey[100]}`,
          ":last-of-type": {
            borderBottom: "none"
          },
          py: 1
        }}
      >
        <CoreComponents.Loader show={isSaving} />
        <TaskViewQuotesListTemplate
          sx={{
            minHeight: {
              xs: "64px",
              md: "auto"
            },
            pb: {
              xs: 5,
              md: 1,
            },
          }}
        >
          <CoreComponents.QuoteListCompany
            company={quote.company}
            onClick={handleViewCompany}
            sx={{
              gridArea: "company",
            }}
            TypographyProps={{
              sx: {
                fontSize: {
                  xs: "18px",
                  md: "14px"
                },
                fontWeight: {
                  xs: "bold",
                  md: "inherit"
                }
              }
            }}
          >
            {showQuoteHistoryButton &&
              <IconButton
                edge="start"
                onClick={handleShowQuoteHistory}
                sx={{
                  mr: 0.5,
                  gridArea: "collapse",
                  position: "relative",
                  top: {
                    xs: "-5px",
                    md: 0
                  }
                }}
              >
                {showQuoteHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            }
          </CoreComponents.QuoteListCompany>
          <TaskViewQuotesListItemRating
            rating={rating}
          />
          <Stack
            direction='row'
            alignItems='center'
            sx={{
              gridArea: {
                xs: "company",
                md: "quote"
              },
              position: "relative",
              top: {
                xs: 50,
                md: 0
              }
            }}>
            <CoreComponents.QuoteListCost
              cost={quote.cost}
            >
              <Box sx={{ p: 2, display: 'flex' }}>
                <CoreModules.Task.TaskView.TaskViewTimelinePaymentSchedule
                  paymentSchedule={quote.paymentSchedule}
                />
              </Box>
            </CoreComponents.QuoteListCost>
            {quote.isModified &&
              <CoreComponents.Tag label="Updated" />
            }
          </Stack>
          <TaskViewQuotesListItemActions
            status={quote.status}
            onAcceptClick={() => handleAccept()}
            onDeclineClick={() => handleDecline()}
            onMessageClick={() => handleViewMessage()}
            onViewClick={() => handleViewClick(quote)}
          />
        </TaskViewQuotesListTemplate>
        <TaskViewQuoteListItemHistory
          quotes={history}
          open={showQuoteHistory}
          onViewClick={(quote) => handleViewClick(quote)}
        />
      </Box>

      <CoreModules.NoteThreadForm
        title={projectTask?.task.name}
        open={open}
        saving={addQuoteNoteThreadQuery.isLoading}
        onSave={(values: any) => handleAddMessage(values)}
        onClose={() => setOpen(false)}
      />

      <TaskViewQuotesListItemRoutes />
    </>
  )
}
export default TaskViewQuotesListItem;